<i18n>
{
  "de": {
    "pageTitle": "Lizenz",
    "licenseValidUntil": "Lizenz gültig bis",
    "propertiesToday": "Anzahl Liegenschaften aktuell",
    "properties": {
      "portfolioSum": "Portfolio",
      "planner": "Planer",
      "reporter": "Reporter",
      "archived": "Archiviert"
    },
    "history": "Verlauf",
    "pendingChanges": "Ausstehende Änderungen",
    "createNewSnapshot": "Snapshot erstellen",
    "downloadCSV": "CSV herunterladen",
    "updateQuery": "Aktualisieren",
    "selectDateRange": "Start- und Enddatum auswählen",
    "selectDate": "Datum auswählen",
    "selectTime": "Uhrzeit auswählen",
    "datePicker": {
      "today": "Heute",
      "yesterday": "Gestern",
      "last7Days": "Letzte 7 Tage",
      "last30Days": "Letzte 30 Tage",
      "lastYear": "Letztes Jahr"
    }
  }
}
</i18n>

<template>
  <MainLayout :portfolio="portfolio" :ready="portfolioReady" class="p-license-page">
    <!-- Page title -->
    <template #header>
      <PageTitle :title="$t('pageTitle')" :portfolio="portfolio" />
    </template>

    <template #default>
      <!-- License -->
      <template v-if="portfolio.inactive_after">
        <div class="license">
          {{ $t('licenseValidUntil') }} {{ new Date(portfolio.inactive_after).toLocaleDateString('de-CH') }}
        </div>
      </template>

      <!-- Summary -->

      <div class="summary">
        <h2>{{ $t('propertiesToday') }}</h2>

        <div class="summary-cards">
          <div class="summary-cards__card">
            <div>
              {{ $t('properties.portfolioSum') }}
            </div>
            <div class="number">{{ portfolio.summary.total || 0 }}</div>
          </div>
          <div class="summary-cards__card">
            <div>
              {{ $t('properties.planner') }}
            </div>
            <div class="number">{{ portfolio.summary.status_counts.planner || 0 }}</div>
          </div>
          <div class="summary-cards__card">
            <div>
              {{ $t('properties.reporter') }}
            </div>
            <div class="number">{{ portfolio.summary.status_counts.reporter || 0 }}</div>
          </div>
          <div class="summary-cards__card">
            <div>
              {{ $t('properties.archived') }}
            </div>
            <div class="number">{{ portfolio.summary.status_counts.archived || 0 }}</div>
          </div>
        </div>
      </div>

      <hr />

      <!-- Events -->
      <div class="events-section">
        <h2>
          {{ $t('history') }}
        </h2>
        <div class="buttons">
          <div class="dates">
            <DatePicker
              v-model="dates"
              type="datetime"
              range
              format="D.M.YYYY HH:mm"
              time-title-format="D.M.YYYY"
              :time-picker-options="{
                start: '00:00',
                step: '00:30',
                end: '23:30',
              }"
              :placeholder="$t('selectDateRange')"
              :shortcuts="shortcuts"
              :show-time-panel="showTimeRangePanel"
              @input="onDateInput"
              @close="handleRangeClose"
            >
              <template v-slot:footer>
                <button class="mx-btn mx-btn-text" @click="toggleTimeRangePanel">
                  {{ showTimeRangePanel ? $t('selectDate') : $t('selectTime') }}
                </button>
              </template>
            </DatePicker>
            <button v-if="isDateUpdated" type="button" class="button" @click="onUpdateStatusEventsQuery">
              {{ $t('updateQuery') }}
            </button>
          </div>

          <ButtonWrapper v-if="isAdmin">
            <button type="button" class="button" @click="onDownloadCsv">
              {{ $t('downloadCSV') }}
            </button>
            <button type="button" class="button" @click="onCreateNewSnapshot">
              {{ $t('createNewSnapshot') }}
            </button>
          </ButtonWrapper>
        </div>

        <StatusEventsTable :portfolio="portfolio" :status-events="statusEvents" />
      </div>

      <!-- Pending changes -->
      <div class="pending-changes-section">
        <h2>
          {{ $t('pendingChanges') }}
        </h2>

        <StatusPendingChangesTable :portfolio="portfolio" :pending-changes="statusPendingChanges" />
      </div>

      <div v-if="statusEventsError">{{ statusEventsError }}</div>
    </template>
  </MainLayout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/de'

import MainLayout from '@/pages/layouts/MainLayout.vue'
import AllPortfoliosMixin from '@/pages/vuex-mixins/AllPortfoliosMixin.vue'
import PortfolioMixin from '@/pages/vuex-mixins/PortfolioMixin.vue'
import StatusEventsMixin from '@/pages/vuex-mixins/StatusEventsMixin.vue'

import PageTitle from '@/components/shared/PageTitle.vue'
import StatusEventsTable from '@/components/license/StatusEventsTable.vue'
import StatusPendingChangesTable from '@/components/license/StatusPendingChangesTable.vue'
import ButtonWrapper from '@/components/shared/ButtonWrapper.vue'

export default {
  name: 'license',

  mixins: [
    AllPortfoliosMixin, // Provides: allPortfolios, allPortfoliosReady
    PortfolioMixin, // Provides: portfolio, portfolioLoading, portfolioReady, energyCarrierProfiles
    StatusEventsMixin, // Provides: statusEvents, statusPendingChanges
  ],

  components: {
    MainLayout,
    PageTitle,
    StatusEventsTable,
    StatusPendingChangesTable,
    ButtonWrapper,
    DatePicker,
  },

  data() {
    return {
      dates: [new Date(new Date().setFullYear(new Date().getFullYear() - 1)), new Date()],
      isDateUpdated: false,
      showTimeRangePanel: false,
      shortcuts: [
        {
          text: this.$t('datePicker.today'),
          onClick() {
            const start = new Date()
            start.setHours(0, 0, 0, 0)
            const end = new Date()
            end.setHours(23, 59, 59, 999)
            return [start, end]
          },
        },
        {
          text: this.$t('datePicker.yesterday'),
          onClick() {
            const start = new Date()
            start.setDate(start.getDate() - 1)
            start.setHours(0, 0, 0, 0)
            const end = new Date()
            end.setDate(end.getDate() - 1)
            end.setHours(23, 59, 59, 999)
            return [start, end]
          },
        },
        {
          text: this.$t('datePicker.last7Days'),
          onClick() {
            const start = new Date()
            start.setDate(start.getDate() - 6)
            start.setHours(0, 0, 0, 0)
            const end = new Date()
            end.setHours(23, 59, 59, 999)
            return [start, end]
          },
        },
        {
          text: this.$t('datePicker.last30Days'),
          onClick() {
            const start = new Date()
            start.setDate(start.getDate() - 29)
            start.setHours(0, 0, 0, 0)
            const end = new Date()
            end.setHours(23, 59, 59, 999)
            return [start, end]
          },
        },
        {
          text: this.$t('datePicker.lastYear'),
          onClick() {
            const start = new Date()
            start.setFullYear(start.getFullYear() - 1)
            start.setHours(0, 0, 0, 0)
            const end = new Date()
            end.setHours(23, 59, 59, 999)
            return [start, end]
          },
        },
      ],
    }
  },

  computed: {
    ...mapGetters({
      isAdmin: 'account/admin',
    }),
  },

  mounted() {
    if (this.allPortfolios) {
      this.refreshAll()
    }
  },

  methods: {
    ...mapActions({
      refreshAll: 'portfolio/refreshAll',
      createSnapshot: 'statusEvents/createSnapshot',
      downloadCsv: 'statusEvents/downloadCsv',
      getStatusEvents: 'statusEvents/getStatusEventsByPortfolioId',
      exportStatusEventsAsCsv: 'statusEvents/exportStatusEventsAsCsv',
    }),

    //
    onCreateNewSnapshot() {
      const today = new Date()
      today.setHours(23, 59, 59, 999)
      this.createSnapshot({
        portfolioId: this.portfolio_id,
        dates: [this.dates[0], today],
      })
      this.dates = [this.dates[0], today]
    },

    //
    onDownloadCsv() {
      this.exportStatusEventsAsCsv({
        portfolioId: this.portfolio_id,
        dates: this.dates,
      })
    },

    //
    onUpdateStatusEventsQuery() {
      this.getStatusEvents({
        portfolioId: this.portfolio_id,
        dates: this.dates,
      })
      this.isDateUpdated = false
    },

    //
    onDateInput() {
      this.isDateUpdated = true
    },

    toggleTimeRangePanel() {
      this.showTimeRangePanel = !this.showTimeRangePanel
    },

    handleRangeClose() {
      this.showTimeRangePanel = false
    },
  },

  head() {
    return {
      title: () => {
        return { inner: this.$t('pageTitle') }
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.p-license-page {
  & .license {
    background-color: #f4f4f4;
    padding: var(--spacing-m);
    border-radius: 6px;
    margin: var(--spacing-l) 0;
  }

  & hr {
    margin: var(--spacing-m) 0;
    border: none;
    height: 1px;
    background-color: #eee;
  }

  /* Summary */
  .summary {
    & .summary-cards {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: var(--spacing-m);
      margin-top: var(--spacing-m);

      & .summary-cards__card {
        flex: 1;
        padding: var(--spacing-m);
        border: 1px solid #eee;
        border-radius: 6px;
        background-color: #fff;

        & .number {
          font-weight: 900;
          font-size: 32px;
          line-height: 40px;
        }
      }
    }
  }

  & .buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 72px;
    margin-bottom: var(--spacing-s);

    & .dates {
      display: flex;
      flex-direction: row;
      align-items: center;

      & * + .mx-datepicker {
        margin-left: var(--spacing-s);
      }
    }
  }

  /* Events & Pending Changes */
  & .events-section,
  & .pending-changes-section {
    margin-top: var(--spacing-l);
  }

  & .pending-changes-section {
    width: calc((((100% / 2) - (var(--spacing-m) / 2))));
    margin-bottom: 80px;

    @media screen and (max-width: 1600px) {
      width: calc((1600px - (var(--spacing-m) * 2)) / 2);
      max-width: 100%;
    }
  }

  & hr {
    margin: var(--spacing-l) 0;
  }
}
</style>
